import React from 'react'
import { Typography } from 'antd'
import { Helmet } from 'react-helmet'
import MainLayout from '@layouts/MainLayout'
import { OutboundLink } from 'gatsby-plugin-gtag'

export default () => (
  <MainLayout>
    <Helmet>
      <title>What I&apos;m doing now</title>
      <description>
        Perpetually entangled in this weird thing we call life.
      </description>
    </Helmet>
    <div className='flex flex-col'>
      <Typography.Title
        level={2}
        className='text-primary-color font-headers font-bold'
      >
        What I&apos;m doing now
      </Typography.Title>
      <div className='px-2 py-1 mb-4 bg-gray-200 border-l-8 border-red-400'>
        <div>
          This is a <b>Now</b> page. Make your own,{' '}
          <OutboundLink
            href='https://nownownow.com/about'
            className='text-red-500'
          >
            see this
          </OutboundLink>
          . Thanks to Derek Sivers for the{' '}
          <OutboundLink href='https://sivers.org/now3' className='text-red-500'>
            inspiration!
          </OutboundLink>
        </div>
        <div>
          Last updated <b>November 8, 2020</b>.
        </div>
      </div>
      <Typography.Paragraph className='font-body text-lg'>
        In no particular order, I&apos;m focusing on these right now:
        <ul>
          <li>
            Getting my personal knowledge management system quite right (still
            working on it)
          </li>
          <li>Finishing the notes site (notes.severian.dev)</li>
          <li>Executing on some long-term career plans</li>
          <li>
            Get started with the next side-project. Some sort of anonymous
            Spotify playlist sharing service. Will update soon.
          </li>
        </ul>
      </Typography.Paragraph>
    </div>
  </MainLayout>
)
